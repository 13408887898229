

































































































































































import { Component, Vue } from 'vue-property-decorator';

import {
  dispatchDeleteSiteParserTask,
  dispatchGetSiteParserTasks,
  dispatchKillSiteParserTask,
  dispatchRunSiteParserTask,
  dispatchStatSiteParserTasks,
  dispatchDownloadSiteParserTaskResult,
  dispatchDownloadSiteParserTaskFile,
} from '@/store/site-parser/actions';
import { readSiteParserTasks } from '@/store/site-parser/getters';
import { SITE_PARSER_UPDATE_RATE } from '../../../constants';
import { estimateTimeToGo } from '../utils';
import { ISiteParserTaskStats } from '@/interfaces/site-parser';
import ProgressBar from '@/components/ProgressBar.vue';
import TooltipBase from '@/components/TooltipBase.vue';

@Component({
  components: {
    ProgressBar,
    TooltipBase,
  },
})
export default class SiteParserTasks extends Vue {
  public headers = [
    {
      text: 'ID',
      sortable: true,
      value: 'id',
      align: 'left',
    },
    {
      text: 'Имя',
      sortable: true,
      value: 'name',
      align: 'left',
    },
    {
      text: 'Описание',
      sortable: true,
      value: 'description',
      align: 'left',
    },
    {
      text: 'Демо',
      sortable: true,
      value: 'demo',
      align: 'left',
    },
    {
      text: 'Менеджер',
      sortable: true,
      value: 'manager',
      align: 'left',
    },
    {
      text: 'Сайты',
      sortable: true,
      value: 'domains',
      align: 'left',
    },
    {
      text: 'Создано',
      sortable: true,
      value: 'date_create',
      align: 'left',
    },
    {
      text: 'Завершено',
      sortable: true,
      value: 'date_end',
      align: 'left',
    },
    {
      text: 'Статус',
      sortable: true,
      value: 'status',
      align: 'left',
    },
    {
      text: 'Действия',
      value: 'id',
    },
  ];

  public updateTimer?: number = undefined;

  get tasks() {
    return readSiteParserTasks(this.$store);
  }

  public tableLoaded = false;

  public async mounted() {
    await dispatchGetSiteParserTasks(this.$store);
    await dispatchStatSiteParserTasks(this.$store);
    this.tableLoaded = true;

    this.updateTimer = setInterval(async () => {
      await dispatchGetSiteParserTasks(this.$store);
      await dispatchStatSiteParserTasks(this.$store);
      }, SITE_PARSER_UPDATE_RATE,
    );
  }

  public destroyed() {
    clearInterval(this.updateTimer);
    this.updateTimer = undefined;
  }

  public async deleteTask(taskId: number) {
    await dispatchDeleteSiteParserTask(this.$store, {id: taskId});
    await dispatchGetSiteParserTasks(this.$store);
  }

  public async runTask(taskId: number) {
    await dispatchRunSiteParserTask(this.$store, {id: taskId});
  }

  public async killTask(taskId: number) {
    await dispatchKillSiteParserTask(this.$store, {id: taskId});
  }

  public async downloadSiteParserTaskResult(taskId: number, result: string) {
    await dispatchDownloadSiteParserTaskResult(this.$store, { id: taskId, result });
  }

  public async downloadSiteParserTaskFile(taskFile: string) {
    await dispatchDownloadSiteParserTaskFile(this.$store, { taskFile });
  }

  public showEstimateTimeToGo(total: number, started: number, current: number, startedTime: Date) {
    if (current >= total || started === undefined || startedTime === undefined) return '';
    return `Еще ${estimateTimeToGo(total, started, current, startedTime)}`;
  }

  public getPercent(statData: ISiteParserTaskStats, divider = 'found_count', divisible = 'total_count') {
    if (!statData[divisible]) return 0;
    return Math.floor(statData[divider] / statData[divisible] * 100);
  }

}
